.swiper {
  margin: -1rem -1rem -3rem -1rem !important;
  padding: 1rem 1rem 3rem 1rem !important;

  .swiper-slide {
    height: unset !important;
  }

  & .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    border: 1px solid $secondary;
    background-color: transparent;
    opacity: 1;
  }

  & .swiper-pagination-bullet-active {
    border: 1px solid $primary;
    background-color: $primary;
  }
}

.swiper-previous-button {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 2.5rem;
  z-index: 3000;
  transform: translate(-0.875rem);
}
@media (min-width: 600px) {
  .swiper-previous-button {
    z-index: unset;
    top: 0;
    transform: translate(-4.25rem, -2.5rem);
  }
}
@media (min-width: 1200px) {
  .swiper-previous-button {
    transform: translate(-5rem, -2rem);
  }
}

.swiper-next-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 2.5rem;
  text-align: right;
  z-index: 3000;
  transform: translate(0.875rem);
}
@media (min-width: 600px) {
  .swiper-next-button {
    z-index: unset;
    top: 0;
    transform: translate(4.25rem, -2.5rem);
  }
}
@media (min-width: 1200px) {
  .swiper-next-button {
    transform: translate(5rem, -2rem);
  }
}

.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.textarea {
  label {
    line-height: 1.825rem;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-family: var(--font-basier-cirlce-bold);
    font-weight: 700;
  }
  textarea {
    background-color: #e2e2e2;
    border: unset;
    outline: none;
    resize: none;
    padding: 0.625rem 1rem 0.5625rem 1rem;
    transition: all 0.05s ease-in-out;
    font-size: 1rem;
    font-family: var(--font-basier-cirlce-regular);
    font-weight: 300;
    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }
    &::placeholder {
      opacity: 1;
      color: #8e8e8e;
      text-transform: uppercase;
      font-size: 1rem;
      font-family: var(--font-basier-cirlce-regular);
      font-weight: 300;
    }
  }
}

// Networks Mapbox Popup

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #eeeeee !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #eeeeee !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #eeeeee !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #eeeeee !important;
}

.mapboxgl-popup {
  max-width: 25rem !important;
  min-width: 20rem;

  .mapboxgl-popup-content {
    background-color: #eeeeee;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 0;

    & .MuiChip-root {
      font-family: var(--font-basier-cirlce-regular);
      font-weight: 300;
    }

    // Locations

    & .MuiChip-category--Kulturbüros {
      background-color: #ef7f69;
      &::before {
        mask-image: url(/img/cultureoffice-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Create {
      background-color: #009de0;
      &::before {
        mask-image: url(/img/createmusicvenue-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Festivals {
      background-color: #d769ef;
      &::before {
        mask-image: url(/img/festival-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Hochschulen {
      background-color: #4fcf73;
      &::before {
        mask-image: url(/img/university-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Labels {
      background-color: #daca1d;
      &::before {
        mask-image: url(/img/label-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Musikschulen {
      background-color: #e76a6a;
      &::before {
        mask-image: url(/img/musicschool-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Musikverlage {
      background-color: #25bf8e;
      &::before {
        mask-image: url(/img/musicpublisher-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Plattenvertriebe {
      background-color: #e44cc2;
      &::before {
        mask-image: url(/img/recordsale-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Proberaumzentren {
      background-color: #9679df;
      &::before {
        mask-image: url(/img/rehearsalspace-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Studios {
      background-color: #f7cf54;
      &::before {
        mask-image: url(/img/studio-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Venues {
      background-color: #7f69ef;
      &::before {
        mask-image: url(/img/venue-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Vereine {
      background-color: #f59d31;
      &::before {
        mask-image: url(/img/club-square.svg);
        background-color: #fff;
      }
    }

    & .MuiChip-category--Agenturen {
      background-color: #0088ff;
      &::before {
        mask-image: url(/img/agency-square.svg);
        background-color: #fff;
      }
    }

    & .location--title {
      text-transform: uppercase;
    }

    & .location-link {
      border: 2px solid #7e69ee !important;
      border-radius: 0 !important;
      color: #7e69ee;
      font-size: 1rem;

      &:hover {
        border: 2px solid #f49c2e !important;
        background-color: transparent;
        color: #f49c2e;
      }
    }

    & .mapboxgl-popup-close-button {
      visibility: hidden;

      &:hover {
        &::after {
          background-color: #f49c2e;
        }
      }

      &::before {
        visibility: visible;
        content: "";
        mask-image: url("/img/CloseIcon.svg");
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        scale: 0.65;
        width: 1.5rem;
        height: 1.5rem;
        transform: translate(1.2rem, -1.115rem);
        z-index: 6000;
      }

      &::after {
        visibility: visible;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 50%;
        cursor: pointer;
        background-color: #7e69ee;
        width: 2rem;
        height: 2rem;
        transform: translate(1rem, -1rem);
        z-index: 5000;
      }
    }
  }
}

// Networks Mapbox Marker
.marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;

  &--venueIcon {
    background-image: url("/img/MapmarkerClubsIcon.svg");
  }
}
